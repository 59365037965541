<template>
  <div class="BigForm__paymentMethod_container typography__textStyrene method-switcher">
    <p class="BigForm__paymentMethod_desc">{{ getTranslatedLabel('method', lang) }}:</p>
    <div class="BigForm__paymentMethod_labelWrapper">
      <label class="BigForm__paymentMethod_label" v-for="(item, index) in methodList.slice(0, Math.ceil(methodList.length / 2))" :key="index" :class="availableMethods.indexOf(item.methodId) === -1 ? 'form-disabled': ''">
        <input type="radio" class="BigForm__hidden BigForm__paymentMethod" :checked="item.methodId === activeMethod" :disabled="availableMethods.indexOf(item.methodId) === -1" @click="changeMethod(item.methodId)" />
        <span class="BigForm__paymentMethod_text">{{ getTranslatedLabel(item.methodId, lang) }}</span>
      </label>
    </div>
    <div class="BigForm__paymentMethod_labelWrapper">
      <label class="BigForm__paymentMethod_label" v-for="(item, index) in methodList.slice(Math.ceil(methodList.length / 2), methodList.length)" :key="index" :class="availableMethods.indexOf(item.methodId) === -1 ? 'form-disabled': ''">
        <input type="radio" class="BigForm__hidden BigForm__paymentMethod" :checked="item.methodId === activeMethod" :disabled="availableMethods.indexOf(item.methodId) === -1" @click="changeMethod(item.methodId)" />
        <span class="BigForm__paymentMethod_text">{{  getTranslatedLabel(item.methodId, lang) }}</span>
      </label>
      <label class="BigForm__paymentMethod_label" v-if="methodList.length % 2 > 0">
      </label>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getTranslatedLabel } from '../../helpers'

export default {
  computed: mapState({
    methodList: state => Object.keys(state.groups)
      .filter(op => !state.groups[op].disabled)
      .filter(op => state.groups[op].dev === undefined || state.groups[op].dev === state.dev)
      .filter(op => !state.groups[op].languages || state.groups[op].languages.indexOf(state.lang) > -1)
      .reduce((arr, key) => {
        arr.push(Object.assign({}, state.groups[key], { methodId: key }))
        return arr
      }, []),
    availableMethods: state => Object.keys(state.operators)
      .filter(opId => state.operators[opId].currencies.indexOf(state.donation.currency) > -1)
      .map(opId => state.operators[opId].group)
      .reduce((x, y) => x.includes(y) ? x : [...x, y], []),
    activeMethod: state => state.operators[state.donation.method].group,
    lang: state => state.lang
  }),
  methods: {
    changeMethod (value) {
      this.$store.dispatch('changeMethod', value)
    },
    getTranslatedLabel
  }
}
</script>
