<template>
  <div class="BigForm__form_footer typography__textStyrene form-controls" v-if="form === 'big'">
    <ApplePayStep v-if='applePayStep' @cancel="unblock" />
    <YandexPayStep v-if='yandexPayStep' @cancel="unblock" />
    <button type="submit" class="BigForm__submit_button donate-button" v-if="this.activeMethod !== 'apay' && this.activeMethod !== 'sberpay' && this.activeMethod !== 'yandexpay'" :disabled="loading" @click="donate">{{ loading ? getTranslatedLabel('donate-button-wait', lang) : getTranslatedLabel(actionText, lang) }}</button>
    <button type="submit" class="BigForm__submit_button typography__textStyrene donate-button" v-else-if="this.activeMethod === 'apay'" :disabled="loading" @click="donate">{{ loading ? getTranslatedLabel('donate-button-wait', lang) : getTranslatedLabel(actionApayText, lang) }}
      <img v-if="!loading" :src="require('@/assets/embeded/apay-white.png')" />
    </button>
    <button type="submit" class="BigForm__submit_button sberpay-button" v-else-if="this.activeMethod === 'sberpay'" :disabled="loading" @click="donate">
      <img v-if="!loading" :src="require('@/assets/embeded/sbpay.png')" />
    </button>
    <YandexPayButton v-else-if="this.activeMethod === 'yandexpay'" @pay="donate" />
    <a class="BigForm__link" @click="showSettings" v-if="!isHidden('settings')">
      <div class="BigForm__linkContent">
        <p>{{ getTranslatedLabel('manage-subscription', lang) }}</p>
        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg" class="BigForm__linkArrow">
          <path d="M0.5 6L11 6M11 6L6 1M11 6L6 11" stroke="#D94236" stroke-width="2"></path>
        </svg>
      </div>
      <div class="BigForm__pencilLine_wrapper">
        <div class="BigForm__pencilLine"></div>
      </div>
    </a>
  </div>
  <div class="MainPageForm__buttonWrp" v-else-if="form === 'main'">
    <ApplePayStep v-if='applePayStep' @cancel="unblock" />
    <button type="submit" class="MainPageForm__button typography__textStyrene donate-button" v-if="this.activeMethod !== 'apay'" :disabled="loading" @click="donate">{{ loading ? getTranslatedLabel('donate-button-wait', lang) : getTranslatedLabel(actionText, lang) }}</button>
    <button type="submit" class="MainPageForm__button typography__textStyrene donate-button" v-else :disabled="loading" @click="donate">{{ loading ? getTranslatedLabel('donate-button-wait', lang) : getTranslatedLabel(actionApayText, lang) }}
      <img v-if="!loading" :src="require('@/assets/embeded/apay-white.png')" />
    </button>
  </div>
  <div class="SmallForm__button typography__textStyrene form-controls" v-else-if="form === 'small'">
    <ApplePayStep v-if='applePayStep' @cancel="unblock" />
    <button type="submit" class="SmallForm__button typography__textStyrene donate-button" v-if="this.activeMethod !== 'apay'" :disabled="loading" @click="donate">{{ loading ? getTranslatedLabel('donate-button-wait', lang) : getTranslatedLabel(actionText, lang) }}</button>
    <button type="submit" class="SmallForm__button typography__textStyrene donate-button" v-else :disabled="loading" @click="donate">{{ loading ? getTranslatedLabel('donate-button-wait', lang) : getTranslatedLabel(actionApayText, lang) }}
      <img v-if="!loading" :src="require('@/assets/embeded/apay-white.png')" />
    </button>
  </div>
  <div class="BigForm__form_footer typography__textStyrene form-controls" v-else-if="form === 'custom'">
    <ApplePayStep v-if='applePayStep' @cancel="unblock" />
    <button type="submit" class="BigForm__submit_button donate-button" v-if="this.activeMethod !== 'apay'" :disabled="loading" @click="donate">{{ loading ? getTranslatedLabel('donate-button-wait', lang) : getTranslatedLabel(actionText, lang) }}</button>
    <button type="submit" class="BigForm__submit_button typography__textStyrene donate-button" v-else :disabled="loading" @click="donate">{{ loading ? getTranslatedLabel('donate-button-wait', lang) : getTranslatedLabel(actionApayText, lang) }}
      <img v-if="!loading" :src="require('@/assets/embeded/apay-white.png')" />
    </button>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ApplePayStep from '@/components/ApplePayStep.vue'
import YandexPayButton from '@/components/YandexPayButton.vue'
import YandexPayStep from '@/components/YandexPayStep.vue'
import { getTranslatedLabel } from '../../helpers'

export default {
  components: { ApplePayStep, YandexPayButton, YandexPayStep },
  inject: ['$validator'],
  data: () => ({
    loading: false,
    applePayStep: false,
    yandexPayStep: false
  }),
  computed: mapState({
    form: state => state.formType,
    lang: state => state.lang,
    actionText: state => state.actionText,
    actionApayText: state => state.actionApayText,
    hiddenFields: state => state.hiddenFields,
    activeMethod: state => state.operators[state.donation.method].group
  }),
  mounted: function () {
    const state = this.$store.state
    const isYandexPayActive = state.queryParams.__YP__ !== undefined
    if (isYandexPayActive) this.yandexPayStep = true
  },
  methods: {
    isHidden: function (fieldName) {
      return this.hiddenFields.indexOf(fieldName) > -1
    },
    async donate () {
      const isValid = await this.$validator.validateAll()
      if (!isValid) return
      this.loading = true
      if (this.activeMethod === 'apay') {
        this.applePayStep = true
      } else if (this.activeMethod === 'yandexpay') {
        this.yandexPayStep = true
      } else {
        this.$store.dispatch('donate')
      }
    },
    unblock () {
      this.loading = false
      this.applePayStep = false
      this.yandexPayStep = false
    },
    showSettings () {
      this.$store.dispatch('settingsPage')
    },
    getTranslatedLabel
  }
}
</script>

<style scoped>
  button.donate-button,
  button.donate-button:focus,
  button.donate-button:hover {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  button.donate-button img {
    height: 2rem;
    margin-left: 0.5rem;
  }
  button.sberpay-button {
    background: none;
    flex-direction: column;
  }
  button.sberpay-button img {
    height: 100%;
  }
  button.yandex-button {
    background: #fff;
    flex-direction: column;
    align-items: center;
    border: 1px solid #000;
    padding: 15px;
    border-radius: 10px;
  }
  button.yandex-button:disabled,
  button.yandex-button[disabled] {
    opacity: 0.2;
  }
  button.yandex-button img {
    height: 100%;
  }
</style>
