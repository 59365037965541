<template>
  <div class="ya-pay">
    <form v-if="threeDs" :action="AcsUrl" method="POST" ref="threeDs">
      <input type="hidden" name="PaReq" :value="PaReq">
      <input type="hidden" name="MD" :value="MD">
      <input type="hidden" name="TermUrl" :value="TermUrl">
    </form>
  </div>
</template>

<script>
import donateApi from '../api/donate'
import externalApi from '../api/external'
import { generateUUID } from '../helpers'
import { mapState } from 'vuex'

export default {
  props: ['external'],
  data: () => ({
    threeDs: false,
    AcsUrl: false,
    PaReq: false,
    MD: false,
    TermUrl: 'https://donate.podari-zhizn.ru/api/donate/reports/cp'
  }),
  computed: mapState({
    donation: state => state.donation,
    method: state => state.donation.method,
    host: state => state.host,
    queryParams: state => state.queryParams
  }),
  mounted () {
    this.initiateDonation()
  },
  updated: function () {
    this.$nextTick(function () {
      if (this.threeDs) {
        const threeDsForm = this.$refs.threeDs
        threeDsForm.submit()
      }
    })
  },
  methods: {
    initiateDonation () {
      if (this.queryParams.__YP__) {
        const request = JSON.parse(this.queryParams.__YP__)
        const sum = request.payload.orderAmount
        const email = request.payload.billingContact.email
        const method = 'yk-sp'
        const donationData = Object.assign({}, this.donation, { sum, email, method })
        // this.processPayment(request.payload.token, donationData)
      } else {
        this.donate()
      }
    },
    donate () {
      donateApi.getConfig((err, res) => {
        if (err) return this.abort()
        this.createYandexPayPayment(res)
      })
    },
    abort () {
      this.$emit('cancel')
    },
    showSuccessPage () {
      if (this.external) {
        this.$parent.$parent.finishTransaction(true)
      } else {
        this.$store.dispatch('thanksPage')
      }
    },
    showErrorPage () {
      if (this.external) {
        this.$parent.$parent.finishTransaction(false)
      } else {
        window.location.href = this.baseRedirectUrl + '/error'
      }
    },
    show3DSPage (model) {
      this.AcsUrl = model.AcsUrl
      this.PaReq = model.PaReq
      this.MD = model.TransactionId
      this.threeDs = true
    },
    createYandexPayPayment (config) {
      const donation = this.donation
      const YaPay = window.YaPay
      const paymentData = {
        env: YaPay.PaymentEnv.Production,
        version: 2,
        countryCode: YaPay.CountryCode.Ru,
        currencyCode: YaPay.CurrencyCode.Rub,
        merchant: {
          id: config.YaPayMerchantId,
          name: 'Podari Zhizn',
          url: this.host
        },
        order: {
          id: generateUUID(),
          total: { amount: donation.sum },
          items: [
            { label: 'Podari Zhizn donation', amount: donation.sum }
          ]
        },
        requiredFields: {
          billingContact: {
            email: true
          }
        },
        paymentMethods: [
          {
            type: YaPay.PaymentMethodType.Card,
            gateway: 'cloudpayments',
            gatewayMerchantId: config.CPAccountId,
            allowedAuthMethods: [YaPay.AllowedAuthMethod.PanOnly],
            allowedCardNetworks: [
              YaPay.AllowedCardNetwork.Visa,
              YaPay.AllowedCardNetwork.Mastercard,
              YaPay.AllowedCardNetwork.Mir,
              YaPay.AllowedCardNetwork.Maestro,
              YaPay.AllowedCardNetwork.VisaElectron
            ]
          }
        ]
      }

      YaPay.createPayment(paymentData)
        .then(function(payment) {
          payment.on(YaPay.PaymentEventType.Error, event => {
            console.error(event)
            payment.complete(YaPay.CompleteReason.Error)
            return this.abort()
          })
          payment.on(YaPay.PaymentEventType.Abort, event => {
            console.error(event)
            return this.abort()
          })
          payment.on(YaPay.PaymentEventType.Process, event => {
            const { token } = event
            return this.processPayment(token)
          })
          payment.checkout()
        }.bind(this))
        .catch(function(err) {
          if (err) console.error(err)
          return this.abort()
        }.bind(this))
    },
    processPayment (token, donationData) {
      const donation = donationData || this.donation
      console.log(donation)
      const host = this.host
      donation.cryptogram = atob(token)
      donation.method = 'yk-sp'
      donateApi.donate(donation, (err, res) => {
        if (err) return this.abort()
        const isFinished = res.Succees
        externalApi.identifyDonation(res.donation, host, err => {
          if (err) {
            console.error(err)
            return this.abort()
          }
          if (!isFinished) {
            if (res.Model && res.Model.AcsUrl) {
              return this.show3DSPage(res.Model)
            } else {
              return this.abort()
            }
          } else {
            return this.showSuccessPage()
          }
        })
      })
    }
  }
}
</script>

<style>
 .ya-pay {
   display: none;
 }
</style>