import { EXPERIMENTAL_VERSIONS, PROJECTS } from '../settings'
import { changeState, registerDonation } from './mindbox'

export default {
  identifyDonation (donation, host, cb) {
    let dataLayer = window.dataLayer || []
    if (host === 'podari-zhizn.ru' || host === 'new.podari-zhizn.ru') {
      dataLayer.push({
        'event': 'checkout',
        'df_PayPeriod': donation.month ? 'Ежемесячно' : 'Разово',
        'df_PaySystem': donation.method,
        'df_Project': PROJECTS[donation.project],
        'df_sum': donation.sum,
        'df_currency': donation.currency
      })
    } else {
      dataLayer.push({
        'event': 'donateForm_New',
        'df_PayPeriod': donation.month ? 'Ежемесячно' : 'Единоразово',
        'df_PaySystem': donation.method,
        'df_Project': PROJECTS[donation.project],
        'df_sum': donation.sum,
        'df_currency': donation.currency
      })
    }

    registerDonation(donation, function (err) {
      if (err) return cb(err)
      changeState(donation, 'InCart', function (err) {
        if (err) return cb(err)
        changeState(donation, 'FilledData', function (err) {
          if (err) return cb(err)
          cb()
        })
      })
    })
  },
  indentifyDonor () {
    const ga = window.ga || {}
    const gaCookie = document.cookie.match(/_ga=(.+?);/)
    const yaCookie = document.cookie.match(/_ym_uid=(.+?);/)
    let GAClientId = ga.loaded ? ga.getAll()[0].get('clientId') : null
    if (GAClientId === null && gaCookie && gaCookie.length > 0) GAClientId = gaCookie[1].split('.').slice(-2).join('.')
    let YMClientId = yaCookie && yaCookie.length > 0 ? yaCookie[1] : null
    return { GAClientId, YMClientId }
  },
  indentifyExperiment (queryString) {
    if (queryString.utm_expid && queryString.utm_expid.split('.').length === 3) {
      const parts = queryString.utm_expid.split('.')
      const experimentData = {}
      experimentData['experiment_' + parts[1]] = parts[2]
      if (EXPERIMENTAL_VERSIONS[parts[1] + '.' + parts[2]]) {
        experimentData['experiment_version'] = EXPERIMENTAL_VERSIONS[parts[1] + '.' + parts[2]]
      }
      return experimentData
    }
    return {}
  }
}
