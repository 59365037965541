<template>
  <div class="PaymentSwitch__root typography__textStyrene month-switcher">
    <label class="PaymentSwitch__label">
      <input type="radio" value="one-off" class="PaymentSwitch__hidden PaymentSwitch__paymentSwitch" :checked="!month" @click="switchMonth(false)" />
      <span class="PaymentSwitch__text">
        <span>{{ getTranslatedLabel('one-time', lang) }}</span>
      </span>
    </label>
    <label class="PaymentSwitch__label">
      <input type="radio" name="payment_switch" value="monthly" class="PaymentSwitch__hidden PaymentSwitch__paymentSwitch" :checked="month" @click="switchMonth(true)" />
      <span class="PaymentSwitch__text">
        <span>{{ getTranslatedLabel('monthly', lang) }}</span>
      </span>
    </label>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getTranslatedLabel } from '../../helpers'

export default {
  computed: mapState({
    month: state => state.donation.month,
    lang: state => state.lang
  }),
  methods: {
    switchMonth (value) {
      this.$store.dispatch('changeMonth', value)
    },
    getTranslatedLabel
  }
}
</script>
