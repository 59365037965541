<template>
  <div class="apple-pay-warning">
    Apple Pay с картами банков ВТБ, Открытие, Совкомбанк, Новикомбанк, Промсвязьбанк не работает. Пожалуйста, введите данные карты, чтобы сделать пожертвование.
  </div>
</template>

<script>
export default {
  methods: {
  }
}
</script>

<style scoped>
  .apple-pay-warning {
    color: #777;
  }
</style>
