<template>
  <label class="BigForm__select_label typography__textStyrene dropdown" :class="disabled ? 'form-disabled': ''">
    <span class="BigForm__select_placeholder typography__micro">
      {{ label }}
    </span>
    <select class="BigForm__select" :disabled="disabled" v-model="selected">
      <option v-for="(projectName, projectId) in values" :key="projectId" v-bind:value="projectId">{{ projectName }}</option>
    </select>
  </label>
</template>

<script>
export default {
  props: ['values', 'value', 'disabled', 'label'],
  data: function () {
    return {
      selected: this.value
    }
  },
  watch: {
    selected () {
      this.selectProject(this.selected)
    },
    value (value) {
      this.selected = value
    }
  },
  methods: {
    selectProject (projectId) {
      this.$emit('selected', parseInt(projectId))
    }
  }
}
</script>
