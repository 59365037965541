<template>
  <div class="yandex-button" ref="button"></div>
</template>

<script>
export default {
  mounted () {
    this.mountButton()
  },
  methods: {
    mountButton () {
      const YaPay = window.YaPay

      const yaPayButton = new YaPay.Button({
        type: YaPay.ButtonType.Simple,
        theme: YaPay.ButtonTheme.WhiteOutlined,
        width: YaPay.ButtonWidth.Auto
      })
      yaPayButton.mount(this.$refs.button)
      yaPayButton.on(YaPay.ButtonEventType.Click, this.pay)
    },
    pay () {
      this.$emit('pay')
    }
  }
}
</script>
