<template>
  <div class="external-operator-form">
    <div class="qr-code BigForm__form" v-if="method === 'qr-code' && lang === 'ru'">
      <div class="BigForm__form_label typography__textStyrene">
        <a :href="qr.link" target="_blank">
          <img class="qr-image" :src="qr.image" />
        </a>
      </div>
      <div class="BigForm__form_label typography__textStyrene" v-html="getTranslatedLabel('qr-code-intro', lang)">
      </div>
    </div>
    <div class="external-static" v-if="method === 'pp-cards-external' || method === 'cards-external'">
      <div class="external-static__content paypal-external">
        <div v-if="currency === 'USD'">
          <div class="BigForm__form_label typography__textStyrene external-static__text" v-html="getTranslatedLabel('paypal-external-intro-usd', lang)">
          </div>
          <div class="BigForm__form_footer typography__textStyrene">
            <a class="BigForm__submit_button external-link" href="https://donorbox.org/supportpl" target="_blank" v-html="getTranslatedLabel('donate-button', lang)"></a>
          </div>
        </div>
        <div v-if="currency === 'EUR'">
          <div class="BigForm__form_label typography__textStyrene external-static__text" v-html="getTranslatedLabel('paypal-external-intro-eur', lang)">
          </div>
          <div class="BigForm__form_footer typography__textStyrene">
            <a class="BigForm__submit_button external-link" href="https://www.paypal.com/donate/?hosted_button_id=VVHV7RLKV3XB6" target="_blank" v-html="getTranslatedLabel('donate-button', lang)"></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getTranslatedLabel } from '../../helpers'

export default {
  props: ['method'],
  computed: mapState({
    lang: state => state.lang,
    currency: state => state.donation.currency,
    qr: state => state.qr
  }),
  methods: {
    getTranslatedLabel
  }
}
</script>

<style scoped>
  .external-operator-form .qr-code .qr-image {
    max-width: 100%;
    padding-right: 10px;
  }
  .external-operator-form .qr-code >>> p {
    margin: 10px 0;
  }
  .external-operator-form .external-link {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .external-operator-form .external-static {
    display: flex;
    justify-content: center;
  }
  .external-static__text {
    text-align: center;
  }
  .external-static__content.paypal-external .BigForm__form_footer {
    justify-content: center;
  }
  @media screen and (max-width: 1599px) and (min-width: 1280px) {
    .external-static__text {
      width: 430px;
      max-width: 430px;
    }
  }
</style>
