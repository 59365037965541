<template>
  <div class='thanks typography__lead'>
    <div class='thanks-text' v-if="thanksText === 'default'" v-html="form === 'big' ? getTranslatedLabel('bigform-thanks', lang) : getTranslatedLabel('default-thanks', lang)">
    </div>
    <div class='thanks-text' v-else v-html="thanksText">
    </div>
    <div class="BigForm__form_footer typography__textStyrene form-controls">
      <a class="BigForm__link" @click="showForm">
        <div class="BigForm__linkContent">
          <p>{{ getTranslatedLabel('return-button', lang) }}</p>
          <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg" class="BigForm__linkArrow">
            <path d="M0.5 6L11 6M11 6L6 1M11 6L6 11" stroke="#D94236" stroke-width="2"></path>
          </svg>
        </div>
        <div class="BigForm__pencilLine_wrapper">
          <div class="BigForm__pencilLine"></div>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getTranslatedLabel, scrollToElementWithOffset } from '../../helpers'

export default {
  computed: mapState({
    thanksText: state => state.thanksText,
    form: state => state.formType,
    lang: state => state.lang
  }),
  methods: {
    showForm () {
      this.$emit('reset')
    },
    getTranslatedLabel
  },
  mounted () {
    scrollToElementWithOffset(this.$el, 100)
  }
}
</script>
