<template>
  <div :class="formClass + ' formStyles__amountContainer typography__textStyrene'">
    <label class="Amount__root" v-for="sumValue in scale" :key="sumValue" :class="form === 'small' ? 'SmallForm__amountLabel' : ''">
      <input type="radio" :value="sumValue" class="Amount__amount" :checked="sumValue === sum && !customInput" @click="setSum(sumValue)" />
      <span class="Amount__amount_text">
        {{ activeCurrency === 'RUB' ? sumValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + ' ' + symbol : symbol + ' ' + sumValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }}
      </span>
    </label>
    <label class="OtherAmount__root" :class="form === 'small' ? 'SmallForm__otherAmount' : ''">
      <input type="number" placeholder=" " class="OtherAmount__amount_input" @focus="switchToCustomSumInput" v-if="!customInput" />
      <input type="number" placeholder=" " class="OtherAmount__amount_input" v-model="sum" v-else />
      <span class="OtherAmount__amount_placeholder">{{ isMobile ? getTranslatedLabel('other-sum-mobile', lang) : getTranslatedLabel('other-sum', lang) }}</span>
    </label>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getTranslatedLabel } from '../../helpers'

export default {
  data: function () {
    return {
      scaleSize: 3,
      customInput: false
    }
  },
  computed: {
    inScale: function () {
      return this.scale.indexOf(this.sum) > -1 && !this.customInput
    },
    sum: {
      get () {
        return this.$store.state.donation.sum
      },
      set (value) {
        this.changeSum(value)
      }
    },
    ...mapState({
      scale: function (state) {
        return state.currencies[state.donation.currency].scale.slice(0, this.scaleSize)
      },
      symbol: state => state.currencies[state.donation.currency].symbol,
      activeCurrency: state => state.donation.currency,
      formClass: state => {
        let formClass = 'BigForm__amount_container'
        if (state.formType === 'main') {
          formClass = 'MainPageForm__amountContainer'
        } else if (state.formType === 'small') {
          formClass = 'SmallForm__amount_container'
        } else if (state.formType === 'custom') {
          formClass = 'CustomForm__amount_container'
        }
        return formClass
      },
      form: state => state.formType,
      lang: state => state.lang,
      isMobile: state => state.donation.mobile
    })
  },
  methods: {
    setSum (value) {
      this.customInput = false
      this.changeSum(value)
    },
    changeSum (value) {
      this.$store.commit('updateDonation', { prop: 'sum', value })
    },
    switchToCustomSumInput () {
      this.customInput = true
    },
    getTranslatedLabel
  },
  watch: {
    sum () {
      if (this.scale.indexOf(this.sum) === -1) {
        this.switchToCustomSumInput()
      }
    }
  },
  mounted () {
    if (this.scale.indexOf(this.sum) === -1) {
      this.switchToCustomSumInput()
    }
  }
}
</script>
