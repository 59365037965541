import request from './request'
import { MINDBOX_API } from '../settings'

export const registerDonation = (donation, cb) => {
  const operationId = 'UnregisteredUserDonate'
  const deviceUUID = getDeviceUUID()
  if (!deviceUUID) return cb(new Error('No deviceUUID'))
  const body = {
    'customer': {
      'email': donation.email,
      'lastName': donation.lastname,
      'firstName': donation.firstname
    },
    'order': {
      'ids': {
        'externalSystemPodariZhizn': donation._id
      },
      'customFields': {
        'recurrent': donation.month
      },
      'totalPrice': donation.sum,
      'lines': [
        {
          'product': {
            'ids': {
              'podariZhizn': donation.project
            }
          },
          'basePricePerItem': donation.sum,
          'quantity': '1'
        }
      ],
      'payments': [
        {
          'type': donation.methodId,
          'amount': donation.sum
        }
      ]
    }
  }

  const data = {
    op: operationId,
    deviceUUID,
    body
  }

  request('POST', MINDBOX_API, data, cb)
}

export const changeState = (donation, state, cb) => {
  const operationId = 'UpdateOrderDonate'
  const deviceUUID = getDeviceUUID()
  const body = {
    'customer': {
      'email': donation.email
    },
    'order': {
      'ids': {
        'externalSystemPodariZhizn': donation._id
      },
      'customFields': {
        'recurrent': donation.month
      },
      'totalPrice': donation.sum,
      'lines': [
        {
          'product': {
            'ids': {
              'podariZhizn': donation.project
            }
          },
          'basePricePerItem': donation.sum,
          'status': state,
          'quantity': '1'
        }
      ],
      'payments': [
        {
          'type': donation.methodId,
          'amount': donation.sum
        }
      ]
    }
  }

  const data = {
    op: operationId,
    deviceUUID,
    body
  }

  request('POST', MINDBOX_API, data, cb)
}

const getDeviceUUID = () => {
  return getCookie('mindboxDeviceUUID') || ''
}

const getCookie = (name) => {
  var value = '; ' + document.cookie
  var parts = value.split('; ' + name + '=')
  if (parts.length === 2) return parts.pop().split(';').shift()
}
