<template>
  <div class="external-footer">
    <div class="BigForm__form" v-if="method === 'assist-cards' && lang === 'ru'">
      <img class="pay-system-logos" :src="require('../../assets/embeded/pay-system-logos.png')" />
      <a href="https://donate.podari-zhizn.ru/assets/security-info.pdf" target="_blank">
        Гарантия безопасности и возврата платежа
      </a>
    </div>
    <div class="BigForm__form" v-if="activeMethod == 'apay'">
      <ApplePayWarning />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ApplePayWarning from '@/components/ApplePayWarning.vue'
import { getTranslatedLabel } from '../../helpers'

export default {
  components: { ApplePayWarning },
  computed: mapState({
    lang: state => state.lang,
    method: state => state.donation.method,
    activeMethod: state => state.operators[state.donation.method].group
  }),
  methods: {
    getTranslatedLabel
  }
}
</script>

<style scoped>
  .external-footer {
    display: block;
    width: 100%;
  }

  .external-footer a {
    color: #777;
  }

  .external-footer .BigForm__form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border: none;
  }

  .external-footer .BigForm__form > * {
    max-width: 50%;
  }

  .external-footer .BigForm__form > .pay-system-logos {
    max-width: 250px;
  }

  @media screen and (max-width: 767px) {
    .external-footer .BigForm__form {
      display: block;
      margin-top: 28px;
      text-align: center;
    }

    .external-footer .BigForm__form > * {
      display: block;
      max-width: 100%;
      margin-bottom: 10px;
    }

    .external-footer .BigForm__form > .pay-system-logos {
      margin: 0 auto 10px;
    }
  }
.BigForm__form_footer {
    margin-top: 28px;
    text-align: center;
}
</style>
