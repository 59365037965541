<template>
  <div></div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: ['external'],
  computed: mapState({
    paymentData: state => state.redirectParams,
    lang: state => state.lang
  }),
  mounted () {
    this.callApi(this.paymentData)
  },
  methods: {
    cancel () {
      this.$store.dispatch('prevPage')
      this.$emit('cancel')
    },
    showSuccessPage () {
      this.$parent.$parent.finishTransaction(true)
    },
    showErrorPage () {
      this.$parent.$parent.finishTransaction(false)
    },
    callApi (data) {
      if (data.apiMethod === 'cp') {
        const request = {
          publicId: data.accountId,
          amount: data.sum,
          currency: data.currency,
          invoiceId: data.order,
          accountId: data.email,
          skin: 'mini'
        }

        if (data.data) {
          request.data = data.data
        }

        /* global cp */
        const lang = this.lang === 'en' ? 'en-US' : 'ru-RU'
        const widget = new cp.CloudPayments({ language: lang })
        widget.outsideClickListener = function (e) {
          if (!document.getElementById('buttonsContainer').contains(e.target)) {
            this.cancel()
            widget.hideWindow()
          }
        }.bind(this)
        widget.pay('charge',
          request,
          {
            onSuccess: function (options) {
              console.log('SUCCESS')
              this.showSuccessPage()
            }.bind(this),
            onFail: function (reason, options) {
              console.log('FAIL', reason, options)
              if (reason !== 'User has cancelled') {
                this.showErrorPage()
              } else {
                this.cancel()
              }
            }.bind(this)
          }
        )
      }
    }
  }
}
</script>
