<template>
  <div class="BigForm__currency_container currency">
    <label class="BigForm__currency_label" v-for="currency in Object.keys(currencies)" :key="currency">
      <input type="radio" :value="currency" class="BigForm__hidden BigForm__currency" :checked="currency === activeCurrency" @click="changeCurrency(currency)" />
      <span :class="'BigForm__currency_text typography__textStyrene BigForm__' + currency + '_check'">{{ getTranslatedLabel(currency.toLowerCase(), lang) }}</span>
    </label>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getTranslatedLabel } from '../../helpers'

export default {
  computed: mapState({
    activeCurrency: state => state.donation.currency,
    currencies: state => state.currencies,
    lang: state => state.lang
  }),
  methods: {
    changeCurrency (value) {
      this.$store.dispatch('changeCurrency', value)
    },
    getTranslatedLabel
  }
}
</script>
