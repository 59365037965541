import Vue from 'vue'
import VueRouter from 'vue-router'
import VeeValidate from 'vee-validate'
import store from './store'
import External from './components/externalV2/External.vue'
import VueTippy, { TippyComponent } from 'vue-tippy'
import 'tippy.js/themes/light-border.css'

Vue.use(VueRouter)
Vue.use(VeeValidate)

Vue.config.productionTip = false
Vue.use(VueTippy)
Vue.component('tippy', TippyComponent)

class GLDonate {
  constructor (settings) {
    this.el = settings.el
    this.settings = settings
  }

  mount () {
    const settings = this.settings

    this.app = new Vue({
      router: this.createRouter(),
      el: this.el,
      store: store.createStore(),
      render: h => h(External, {
        props: { settings },
        ref: 'gl-donate'
      }),
      mounted: function () {
        const queryString = this.$route.query
        this.$store.dispatch('parseQueryString', queryString)
        this.$store.dispatch('identifyExperiment', queryString)
      }
    })
    return this
  }

  modify (props) {
    const glDonate = this.app.$refs['gl-donate']
    glDonate.modifySettings(props)
    return this
  }

  createRouter () {
    return new VueRouter({
      mode: 'history',
      routes: []
    })
  }
}
window.GLDonate = GLDonate
