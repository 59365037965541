<template>
  <div class="subscription-settings-form typography__lead">
    <div class="first-step" v-if="!error && !sent && !done" v-html="getTranslatedLabel('settings-form-intro', lang)">
    </div>
    <div class="second-step" v-if="sent" v-html="getTranslatedLabel('settings-form-sent', lang)">
    </div>
    <div class="final-step" v-if="done" v-html="getTranslatedLabel('settings-form-done', lang)">
    </div>
    <div class="error-step" v-if="error" v-html="getTranslatedLabel('settings-form-error', lang)">
    </div>
    <div class="form" v-if="!error && !sent && !done">
      <div class="form-input">
        <label class="FormInput__root typography__textStyrene">
          <input type="email" required pattern="^[a-zA-Z0-9.!#$%&amp;'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$" placeholder=" " class="FormInput__input" v-model="email">
          <span class="FormInput__placeholder">Email</span>
          <button type="button" class="FormInput__error_button">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" class="FormInput__error_icon">
              <circle cx="16" cy="16" r="16" fill="#D94236"></circle>
              <rect x="15" y="22" width="2" height="2" fill="white"></rect>
              <rect x="15" y="8" width="2" height="12" fill="white"></rect>
            </svg>
          </button>
          <div class="FormInput__tooltip FormInput__error_tooltip">
            <p class="FormInput__tooltip_text typography__micro">
              {{ getTranslatedLabel('email-symbols-error', lang) }}
            </p>
          </div>
        </label>
      </div>
      <div class="form-input">
        <label class="FormInput__root typography__textStyrene">
          <input type="number" placeholder=" " class="FormInput__input" v-model="currentSum" />
          <span class="FormInput__placeholder">{{ getTranslatedLabel('change-settings-current-sum', lang) }}</span>
        </label>
      </div>
      <div class="form-input">
        <label class="FormInput__root typography__textStyrene">
          <input type="number" placeholder=" " class="FormInput__input" v-model="sum" />
          <span class="FormInput__placeholder">{{ getTranslatedLabel('change-settings-new-sum', lang) }}</span>
        </label>
      </div>
      <div class="form-footer">
        <div class="BigForm__form_footer typography__textStyrene">
          <button class="BigForm__submit_button" @click="changeSettings">{{ getTranslatedLabel('change-settings-button', lang) }}</button>
          <a class="BigForm__link" @click="reset">
            <div class="BigForm__linkContent">
              <p>{{ getTranslatedLabel('make-donation-form', lang) }}</p>
              <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg" class="BigForm__linkArrow">
                <path d="M0.5 6L11 6M11 6L6 1M11 6L6 11" stroke="#D94236" stroke-width="2"></path>
              </svg>
            </div>
            <div class="BigForm__pencilLine_wrapper">
              <div class="BigForm__pencilLine"></div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import donateApi from '@/api/donate'
import { getTranslatedLabel, scrollToElementWithOffset } from '../../helpers'

export default {
  data: () => ({
    'sent': false,
    'error': false,
    'done': false,
    'email': '',
    'currentSum': '',
    'sum': ''
  }),
  computed: mapState({
    lang: state => state.lang
  }),
  methods: {
    changeSettings () {
      if (!this.email || !this.currentSum || !this.sum) {
        this.error = true
        return
      }
      const newPage = true
      donateApi.changeSettings(this.email, this.currentSum, this.sum, newPage, (err, res) => {
        if (err) {
          this.error = true
        } else {
          this.sent = true
        }
      })
    },
    unsubscribe () {
      this.$store.dispatch('unsubscribePage')
    },
    reset () {
      this.$store.dispatch('resetForm')
    },
    parseCurrentState () {
      const { state = '' } = this.$route.query
      if (this[state] !== undefined) this[state] = true
    },
    getTranslatedLabel
  },
  mounted () {
    scrollToElementWithOffset(this.$el, 100)
    this.parseCurrentState()
  }
}
</script>

<style scoped>
  .form {
    margin-top: 40px;
  }
  .form-input {
    display: flex;
  }
  .FormInput__root {
    width: 100%;
  }
  .subscription-settings-form {
    width: 100%;
  }
</style>
