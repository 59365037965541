<template>
  <label :class="formClass + ' Offer__root agreement'">
    <input type="checkbox" class="Offer__check" :class="errors.has('agreement') ? 'Offer__error' : ''" v-validate="'required'" name="agreement" v-model="checked" />
    <span class="Offer__text" v-html="getTranslatedLabel('agreement', lang)"></span>
    <p class="ErrorMessage__root Offer__errorMessage ErrorMessage__errorMessageShow" v-show="errors.has('agreement')">{{ getTranslatedLabel('agreement-error', lang) }}</p>
  </label>
</template>

<script>
import { mapState } from 'vuex'
import { getTranslatedLabel } from '../../helpers'

export default {
  data: () => ({
    checked: false
  }),
  computed: mapState({
    formClass: state => {
      let formClass = 'BigForm__offer_label'
      if (state.formType === 'main') {
        formClass = 'MainPageForm__offer'
      } else if (state.formType === 'small') {
        formClass = 'SmallForm__offer'
      }
      return formClass
    },
    lang: state => state.lang
  }),
  methods: {
    getTranslatedLabel
  },
  inject: ['$validator']
}
</script>

<style scoped>
  .invalid {
    color: #d94236;
  }
  a {
    color: inherit;
    text-decoration: underline;
  }
</style>
