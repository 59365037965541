import Vue from 'vue'
import Vuex from 'vuex'
import cloneDeep from 'lodash.clonedeep'
import donateApi from './api/donate'
import externalApi from './api/external'
import { parseSearchQuery } from './helpers'
import {
  DEFAULT_SUM_GETTER, GET_CURRENCIES, GET_EMBED_CURRENCIES, GET_DEFAULT_OPERATOR,
  OPERATOR_GROUPS, OPERATORS, OTHER_METHODS, PROJECTS, STATS, DEFAULT_FORMTYPE, PRESETS, QR_CONFIG
} from './settings'

Vue.use(Vuex)

// const isSecuredDocument = document.location.protocol === 'https:'
let applePaySupported = false

try {
  applePaySupported = false // window.ApplePaySession && isSecuredDocument && window.ApplePaySession.canMakePayments()
} catch (error) {
  console.error('Apple Pay error', error.message)
}

// initial state
const state = {
  step: 1,
  dev: false,
  version: 'default',
  thanksText: 'default',
  errorText: 'default',
  actionText: 'donate-button',
  actionApayText: 'donate-button-apay',
  terms: 'assets/docs/dogovor_oferty_PZh.pdf',
  applePaySupported,
  currencyGetter: GET_CURRENCIES,
  currencies: GET_CURRENCIES(),
  projects: PROJECTS,
  operators: OPERATORS,
  groupsGetter: OPERATOR_GROUPS,
  groups: OPERATOR_GROUPS(applePaySupported),
  sumGetter: DEFAULT_SUM_GETTER,
  sumDesktop: 0,
  sumMobile: 0,
  currencyDesktop: 0,
  currencyMobile: 0,
  fields: ['operator', 'month', 'currency', 'lastname', 'firstname', 'email', 'phone', 'address', 'project', 'comment', 'check', 'settings'],
  hiddenFields: [],
  stats: STATS,
  otherMethods: OTHER_METHODS,
  redirectUrl: '',
  redirectMsg: '',
  redirectParams: {},
  formType: DEFAULT_FORMTYPE,
  centeredForm: false,
  lang: 'ru',
  host: window.location.hostname,
  queryParams: parseSearchQuery(),
  qr: QR_CONFIG,
  donation: {
    sum: DEFAULT_SUM_GETTER(),
    month: false,
    currency: 'RUB',
    email: '',
    method: GET_DEFAULT_OPERATOR(applePaySupported, DEFAULT_FORMTYPE),
    source: 'desktop',
    mobile: false,
    page_source: '',
    child: '',
    firstname: '',
    lastname: '',
    middlename: '',
    address: '',
    city: '',
    country: '',
    zip: '',
    phone: '',
    project: 0,
    comment: 'Благотворительное пожертвование',
    data: {},
    raw: {
      form: 'desktop_new'
    },
    promo_id: ''
  }
}

// actions
const actions = {
  nextPage ({ commit }) {
    commit('setStep', 2)
  },
  prevPage ({ commit }) {
    commit('setStep', 1)
  },
  errorPage ({ commit, state }) {
    if (state.formType === 'big') {
      const url = state.lang === 'en'
        ? `${window.location.origin}/en/donate-error`
        : `${window.location.origin}/ru/donate/error`
      commit('redirect', url)
    } else {
      commit('setStep', 20)
    }
  },
  thanksPage ({ commit, state }) {
    if (state.formType === 'big') {
      const url = state.lang === 'en'
        ? `${window.location.origin}/en/thank-you`
        : `${window.location.origin}/ru/donate/thanks`
      commit('redirect', url)
    } else {
      commit('setStep', 10)
    }
  },
  settingsPage ({ commit }) {
    commit('setStep', 40)
  },
  unsubscribePage ({ commit }) {
    commit('setStep', 30)
  },
  resetForm ({ commit }) {
    commit('clear')
  },
  changeMethod ({ commit, state }, value) {
    const change = {}
    const group = value
    const currency = state.donation.currency
    const month = state.donation.month

    const sameGroupOperators = Object.keys(state.operators).filter(opId => state.operators[opId].group === group)
    // Group of operators, for instance bank cards
    if (sameGroupOperators.length > 1) {
      // Get operators for chosen currency
      const currencyMethods = sameGroupOperators.filter(opId => state.operators[opId].currencies.indexOf(currency) > -1)
      // Find the first method with given currency and month option
      const monthlyMethod = currencyMethods.find(m => m.month === month)
      // If there is one available then we should use it
      // Otherwise we should use first method with given currency
      // and switch off month mode
      if (monthlyMethod) {
        change['method'] = monthlyMethod
      } else {
        change['method'] = currencyMethods[0]
        change['month'] = false
      }
    } else {
      // Non grouped operators
      // If current currency is not available for given operator
      // we should set first available
      const operator = state.operators[sameGroupOperators[0]]
      if (operator.currencies.indexOf(currency) === -1) {
        change['currency'] = operator.currencies[0]
      }
      // If current currency is not available for monthly donations
      // we should set one time donation mode
      if (month && !operator.month) {
        change['month'] = false
      }
      // Set donation method
      change['method'] = sameGroupOperators[0]
    }

    let update = {
      donation: change
    }
    commit('updateState', update)
  },
  changeCurrency ({ commit, state }, value) {
    const change = {}
    const currency = value
    const methodId = state.donation.method
    const operator = state.operators[methodId]
    const month = state.donation.month
    const currencies = state.currencies
    const currentCurrency = state.donation.currency
    const sum = state.donation.sum
    const sumIndex = currencies[currentCurrency].scale.indexOf(sum)

    // If given method works with chosen currency and month mode - just change the currency
    // If given method do not works with chosen currency - we should try to find another one
    // within the group, which will work with chosen currency and month mode
    if (operator.currencies.indexOf(currency) === -1 || (!operator.month && month)) {
      const group = operator.group
      const sameGroupOperators = Object.keys(state.operators).filter(opId => state.operators[opId].group === group)
      let newMethodId = sameGroupOperators.find(opId => state.operators[opId].currencies.indexOf(currency) > -1 && state.operators[opId].month === month)
      if (newMethodId) {
        change['method'] = newMethodId
      } else {
        // or only with chosen currency
        newMethodId = sameGroupOperators.find(opId => state.operators[opId].currencies.indexOf(currency) > -1)
        if (newMethodId) {
          change['method'] = newMethodId
          change['month'] = false
        } else {
          // or other method
          newMethodId = Object.keys(state.operators).find(opId => state.operators[opId].currencies.indexOf(currency) > -1 && (month ? state.operators[opId].month === month : 1))
          change['method'] = newMethodId
        }
      }
    }
    // Change sum in scale when changing the currency
    if (sumIndex > -1) {
      change['sum'] = currencies[value].scale[sumIndex]
    }

    change['currency'] = value
    let update = {
      donation: change
    }
    commit('updateState', update)
  },
  changeMonth ({ commit, state }, value) {
    const change = {
      month: value
    }
    const methodId = state.donation.method
    const operator = state.operators[methodId]
    const currency = state.donation.currency
    if (value) {
      change['project'] = 0
    }

    // If given method works with chosen month mode - just change the month mode
    // If given method do not works with chosen month mode - we should try to find another one
    // within the group, which will work with chosen currency
    if (!operator.month && value) {
      const group = operator.group
      const sameGroupOperators = Object.keys(state.operators).filter(opId => state.operators[opId].group === group)
      let newMethodId = sameGroupOperators.find(opId => state.operators[opId].currencies.indexOf(currency) > -1 && state.operators[opId].month === value)
      if (newMethodId) {
        change['method'] = newMethodId
      } else {
        // or other method
        newMethodId = Object.keys(state.operators).find(opId => state.operators[opId].currencies.indexOf(currency) > -1 && state.operators[opId].month === value)
        change['method'] = newMethodId
      }
    }

    if (!change['method']) {
      const opId = GET_DEFAULT_OPERATOR(state.applePaySupported, state.formType)
      change['method'] = opId
      change['currency'] = state.operators[opId].currencies[0]
    }

    let update = {
      donation: change
    }

    commit('updateState', update)
  },
  switchDeviceVersion ({ commit, state }, version) {
    const mobile = version.mobile
    const versionType = version.version || 'default'
    const source = mobile ? 'mobile' : 'desktop'
    const form = mobile ? 'mobile_new' : 'desktop_new'
    const currencyGetter = state.currencyGetter
    const groupsGetter = state.groupsGetter
    const sumGetter = state.sumGetter
    const groups = mobile
      ? groupsGetter(applePaySupported, true)
      : groupsGetter(applePaySupported)
    const currencies = currencyGetter(mobile)
    const currency = state.currencyDesktop || state.currencyMobile
      ? mobile
        ? state.currencyMobile || state.currencyDesktop
        : state.currencyDesktop
      : state.donation.currency

    const sum = state.sumDesktop || state.sumMobile
      ? mobile
        ? state.sumMobile || state.sumDesktop
        : state.sumDesktop
      : mobile
        ? sumGetter(true)
        : sumGetter()
    let update = {
      currencyGetter,
      version: versionType,
      currencies,
      donation: {
        sum,
        currency,
        mobile,
        source,
        method: GET_DEFAULT_OPERATOR(applePaySupported, state.formType),
        raw: Object.assign({}, state.donation.raw, { form })
      },
      groups
    }
    commit('updateState', update)
  },
  setConfiguration ({ commit, state }, config) {
    let update = {}
    for (let key in config) {
      if (state.hasOwnProperty(key)) {
        update[key] = config[key]
      }
    }

    if (!update.currencyGetter) {
      update.currencyGetter = state.version === 'embed' || state.version === 'external-v2' ? GET_EMBED_CURRENCIES : GET_CURRENCIES
    }
    if (typeof update.currencyGetter === 'object' && update.currencyGetter !== null) {
      const currencySettings = Object.assign({}, update.currencyGetter)
      update.currencyGetter = () => currencySettings
    }
    update.currencies = update.currencyGetter()

    if (update.currencyDesktop || update.currencyMobile) {
      const currency = state.donation.mobile ? (update.currencyMobile || update.currencyDesktop) : update.currencyDesktop
      const currencies = state.currencyGetter()
      if (Object.keys(currencies).indexOf(currency) > -1) {
        if (!update.donation) update.donation = {}
        update.donation.currency = currency
      }
    }

    if (update.groupsGetter) {
      update.groups = update.groupsGetter(state.applePaySupported)
    }

    if (update.sumGetter) {
      const sum = update.sumGetter(state.donation.mobile)
      if (!update.donation) update.donation = {}
      update.donation.sum = sum
    }

    if (update.sumDesktop || update.sumMobile) {
      const sum = state.donation.mobile ? (update.sumMobile || update.sumDesktop) : update.sumDesktop
      if (!update.donation) update.donation = {}
      update.donation.sum = sum
    }

    if (config.formType) {
      update.formType = config.formType
      update.method = GET_DEFAULT_OPERATOR(applePaySupported, config.formType)
    }

    commit('updateState', update)
  },
  parseQueryString ({ commit, state }, queryString) {
    const donation = state.donation
    const update = {}
    const change = {}
    for (let key in queryString) {
      if (key === 'preset') {
        const presetId = queryString['preset']
        const preset = PRESETS[presetId]
        if (preset) {
          for (let key in preset) {
            if (state.hasOwnProperty(key)) {
              update[key] = preset[key]
            }
          }
        }
      }
      if (key === 'line') {
        const line = JSON.parse(queryString.line)
        if (Array.isArray(line)) {
          const currencySettings = {
            'RUB': {
              scale: line.map(i => parseInt(i)),
              symbol: 'Р'
            }
          }
          update.currencyGetter = () => currencySettings
          update.currencies = update.currencyGetter()
        }
      }
      if (donation.hasOwnProperty(key)) {
        change[key] = queryString[key]
        if (key === 'sum') {
          change['sum'] = parseInt(queryString['sum'])
        }
        if (key === 'project') {
          const project = queryString['project']
          change['project'] = PROJECTS[project] ? project : 0
        }
      }
      if (key === 'pageSource') {
        change['page_source'] = queryString['pageSource']
      }
      if (key === 'hide') {
        update.hiddenFields = queryString['hide'].split(',')
      }
      if (key === 'version') {
        update.version = queryString['version']
      }
      if (key === 'form') {
        update.formType = queryString['form']
      }
      if (key === 'dev') {
        update.dev = queryString['dev'] === 'true'
      }
      if (key === 'centered') {
        update.centeredForm = queryString['centered']
      }
      // Show unsubscribe page only in big form
      if (key === 'unsubscribe' && queryString['unsubscribe'] === 'true' && state.formType === 'big') {
        update.step = 30
      }
      // Show settings page only in big form
      if (key === 'settings' && queryString['settings'] === 'true' && state.formType === 'big') {
        update.step = 40
      }
      if (key === 'error' && queryString['error'] === 'true') {
        update.step = 20
      }
      if (key === 'thanks' && queryString['thanks'] === 'true') {
        update.step = 10
      }
      if (key === 'child') {
        console.info(`context: ${queryString['child']}`)
      } else {
        console.info(`no context set`)
      }
    }

    update.donation = change

    commit('updateState', update)
  },
  identifyDonor ({ commit, state }) {
    const donorData = externalApi.indentifyDonor()
    let update = {
      donation: {
        raw: Object.assign({}, state.donation.raw, donorData)
      }
    }
    commit('updateState', update)
  },
  identifyExperiment ({ commit, state }, queryString) {
    const experimentData = externalApi.indentifyExperiment(queryString)
    let update = {
      donation: {
        raw: Object.assign({}, state.donation.raw, experimentData)
      }
    }
    if (experimentData.experiment_version) {
      update.version = experimentData.experiment_version
    }
    commit('updateState', update)
  },
  donate ({ commit, state }) {
    const host = state.host
    const lang = state.lang
    const donation = Object.assign({}, state.donation, { lang })
    donateApi.donate(donation, (err, res) => {
      if (err) {
        commit('setStep', 1)
      } else {
        donation._id = res.donation._id
        donation.methodId = res.donation.methodId
        externalApi.identifyDonation(donation, host, err => {
          if (err) console.error(err)
          const update = {
            redirectMsg: res.message,
            redirectUrl: res.url,
            redirectParams: res.payment
          }
          update.step = res.payment.apiMethod ? 4 : 3
          commit('updateState', update)
        })
      }
    })
  }
}

// mutations
const mutations = {
  updateDonation (state, change) {
    state.donation[change.prop] = change.value
  },
  updateState (state, change) {
    Object.keys(change).forEach(prop => {
      const value = change[prop]
      if (value.constructor === Object && !value.force) {
        Object.keys(value).forEach(nestedProp => {
          state[prop][nestedProp] = value[nestedProp]
        })
      } else {
        if (value.force) delete value.force
        state[prop] = value
      }
    })
  },
  setStep (state, step) {
    state.step = step
  },
  clear (state) {
    state.step = 1
    state.donation.firstname = ''
    state.donation.lastname = ''
    state.donation.middlename = ''
    state.donation.address = ''
    state.donation.city = ''
    state.donation.country = ''
    state.donation.zip = ''
    state.donation.phone = ''
    // state.donation.sum = state.sumGetter(state.donation.mobile)
  },
  redirect (state, url) {
    window.location.href = url
  }
}

export default {
  createStore () {
    const store = {
      state,
      actions,
      mutations
    }
    return new Vuex.Store(cloneDeep(store))
  }
}
