<template>
  <div class="redirect">
    <h2>Подождите немного...</h2>
    <div class="message">{{ message }}</div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: mapState({
    message: state => state.redirectMsg,
    redirectUrl: state => state.redirectParams.noUrlParams ? state.redirectUrl : state.redirectUrl + '?' + Object.keys(state.redirectParams).reduce((queryString, param) => {
      return (queryString.length > 0 ? queryString + '&' : '') + encodeURIComponent(param) + '=' + encodeURIComponent(state.redirectParams[param])
    }, '')
  }),
  mounted () {
    window.location.href = this.redirectUrl
  }
}
</script>
