<template>
  <div></div>
</template>

<script>
import donateApi from '../api/donate'
import externalApi from '../api/external'
import { mapState } from 'vuex'

export default {
  props: ['external'],
  computed: mapState({
    donation: state => state.donation,
    method: state => state.donation.method,
    host: state => state.host
  }),
  mounted () {
    this.donate()
  },
  methods: {
    donate () {
      const session = this.createApplePaySession()
      session.begin()
    },
    abort (session) {
      session.abort()
      this.$emit('cancel')
    },
    showSuccessPage () {
      if (this.external) {
        this.$parent.$parent.finishTransaction(true)
      } else {
        this.$store.dispatch('thanksPage')
      }
    },
    showErrorPage () {
      if (this.external) {
        this.$parent.$parent.finishTransaction(false)
      } else {
        window.location.href = this.baseRedirectUrl + '/error'
      }
    },
    createApplePaySession () {
      const method = this.method
      const donation = this.donation
      const host = this.host
      const applePayRequest = {
        countryCode: 'RU',
        currencyCode: donation.currency,
        supportedNetworks: ['visa', 'masterCard'],
        merchantCapabilities: ['supports3DS'],
        total: { label: 'Podari Zhizn', amount: donation.sum }
      }
      const applePaySession = new window.ApplePaySession(1, applePayRequest)
      applePaySession.onvalidatemerchant = (event) => {
        const { validationURL } = event
        donateApi.startApplePaySession({ validationURL, method }, (err, res) => {
          if (err) return this.abort()
          applePaySession.completeMerchantValidation(res.session)
        })
      }
      applePaySession.onpaymentauthorized = (event) => {
        donation.cryptogram = JSON.stringify(event.payment.token)
        donation.method = method
        donateApi.donate(donation, (err, res) => {
          if (err) return this.abort()
          externalApi.identifyDonation(res.donation, host, err => {
            if (err) console.error(err)
            const status = res.Success ? window.ApplePaySession.STATUS_SUCCESS : window.ApplePaySession.STATUS_FAILURE
            applePaySession.completePayment(status)
            this.showSuccessPage()
          })
        })
      }
      return applePaySession
    }
  }
}
</script>
