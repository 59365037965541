<template>
  <div class="BigForm__form donor-data-form" :class="currencies.length === 1 ? 'one-currency' : ''" v-if="form === 'big'">
    <label v-if="!isMobile" class="FormInput__root typography__textStyrene BigForm__form_label BigForm__surname_label">
      <input type="text" v-validate="'required'" name="lastname" placeholder=" " class="FormInput__input" :class="errors.has('lastname') ? 'invalid FormInput__notValid' : ''" v-model="lastname">
      <span class="FormInput__placeholder">{{ getTranslatedLabel('lastname', lang) }}</span>
      <p class="ErrorMessage__root FormInput__errorMessage" v-show="errors.has('lastname')">
        {{ errors.first('lastname') }}
      </p>
    </label>
    <label v-if="!isMobile" class="FormInput__root typography__textStyrene BigForm__form_label BigForm__name_label">
      <input type="text" placeholder=" " class="FormInput__input" v-model="firstname">
      <span class="FormInput__placeholder">{{ getTranslatedLabel('firstname', lang) }}</span>
    </label>
    <label class="FormInput__root typography__textStyrene BigForm__form_label BigForm__email_label">
      <input type="text" v-validate="'required|email'" :class="errors.has('email') ? 'invalid FormInput__notValid' : ''" name="email" placeholder=" " class="FormInput__input" v-model="email" @input="checkEmail" @change="checkEmail">
      <span class="FormInput__placeholder">{{ getTranslatedLabel('email', lang) }}</span>
      <p class="ErrorMessage__root FormInput__errorMessage" v-show="errors.has('email') && errors.firstByRule('email','required')">
        {{ errors.firstByRule('email','required') }}
      </p>
      <p class="email-suggestion" v-show="emailSuggestion">
        {{ getTranslatedLabel('email-suggestion', lang) }} <span @click="email = emailSuggestion; emailSuggestion = false;">{{ emailSuggestion }}</span>?
      </p>
      <button type="button" class="FormInput__error_button" v-show="errors.has('email')">
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" class="FormInput__error_icon">
          <circle cx="16" cy="16" r="16" fill="#D94236"></circle>
          <rect x="15" y="22" width="2" height="2" fill="white"></rect>
          <rect x="15" y="8" width="2" height="12" fill="white"></rect>
        </svg>
      </button>
      <div class="FormInput__tooltip FormInput__error_tooltip" v-show="errors.has('email')">
        <p class="FormInput__tooltip_text typography__micro">
          {{ errors.first('email') }}
        </p>
      </div>
    </label>
    <label class="FormInput__root typography__textStyrene BigForm__form_label BigForm__phone_label">
      <input type="text" placeholder=" " v-validate="{ regex: /^((7|8)+([0-9]){10})$/ }" :class="errors.has('phone') ? 'invalid FormInput__notValid' : ''" name="phone" class="FormInput__input" v-model="phone">
      <span class="FormInput__placeholder">{{ getTranslatedLabel('phone', lang) }}</span>
      <button type="button" class="FormInput__error_button" v-show="errors.has('phone')">
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" class="FormInput__error_icon">
          <circle cx="16" cy="16" r="16" fill="#D94236"></circle>
          <rect x="15" y="22" width="2" height="2" fill="white"></rect>
          <rect x="15" y="8" width="2" height="12" fill="white"></rect>
        </svg>
      </button>
      <div class="FormInput__tooltip FormInput__error_tooltip" v-show="errors.has('phone')">
        <p class="FormInput__tooltip_text typography__micro">
          {{ errors.first('phone') }}
        </p>
      </div>
    </label>
  </div>
  <div class="BigForm__form donor-data-form" :class="currencies.length === 1 ? 'one-currency' : ''" v-else-if="form === 'custom'">
    <label v-if="!isHidden('lastname')" class="FormInput__root typography__textStyrene CustomForm__form_label">
      <input type="text" v-validate="'required'" name="lastname" placeholder=" " class="FormInput__input" :class="errors.has('lastname') ? 'invalid FormInput__notValid' : ''" v-model="lastname">
      <span class="FormInput__placeholder">{{ getTranslatedLabel('lastname', lang) }}</span>
      <p class="ErrorMessage__root FormInput__errorMessage" v-show="errors.has('lastname')">
        {{ errors.first('lastname') }}
      </p>
    </label>
    <label v-if="!isHidden('firstname')" class="FormInput__root typography__textStyrene CustomForm__form_label">
      <input type="text" placeholder=" " class="FormInput__input" v-model="firstname">
      <span class="FormInput__placeholder">{{ getTranslatedLabel('firstname', lang) }}</span>
    </label>
    <label v-if="!isHidden('email')" class="FormInput__root typography__textStyrene CustomForm__form_label">
      <input type="text" v-validate="'required|email'" :class="errors.has('email') ? 'invalid FormInput__notValid' : ''" name="email" placeholder=" " class="FormInput__input" v-model="email" @input="checkEmail" @change="checkEmail">
      <span class="FormInput__placeholder">{{ getTranslatedLabel('email', lang) }}</span>
      <p class="ErrorMessage__root FormInput__errorMessage" v-show="errors.has('email') && errors.firstByRule('email','required')">
        {{ errors.firstByRule('email','required') }}
      </p>
      <p class="email-suggestion" v-show="emailSuggestion">
        {{ getTranslatedLabel('email-suggestion', lang) }} <span @click="email = emailSuggestion; emailSuggestion = false;">{{ emailSuggestion }}</span>?
      </p>
      <button type="button" class="FormInput__error_button" v-show="errors.has('email')">
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" class="FormInput__error_icon">
          <circle cx="16" cy="16" r="16" fill="#D94236"></circle>
          <rect x="15" y="22" width="2" height="2" fill="white"></rect>
          <rect x="15" y="8" width="2" height="12" fill="white"></rect>
        </svg>
      </button>
      <div class="FormInput__tooltip FormInput__error_tooltip" v-show="errors.has('email')">
        <p class="FormInput__tooltip_text typography__micro">
          {{ errors.first('email') }}
        </p>
      </div>
    </label>
    <label v-if="!isHidden('phone')" class="FormInput__root typography__textStyrene CustomForm__form_label">
      <input type="text" placeholder=" " v-validate="{ regex: /^((7|8)+([0-9]){10})$/ }" :class="errors.has('phone') ? 'invalid FormInput__notValid' : ''" name="phone" class="FormInput__input" v-model="phone">
      <span class="FormInput__placeholder">{{ getTranslatedLabel('phone', lang) }}</span>
      <button type="button" class="FormInput__error_button" v-show="errors.has('phone')">
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" class="FormInput__error_icon">
          <circle cx="16" cy="16" r="16" fill="#D94236"></circle>
          <rect x="15" y="22" width="2" height="2" fill="white"></rect>
          <rect x="15" y="8" width="2" height="12" fill="white"></rect>
        </svg>
      </button>
      <div class="FormInput__tooltip FormInput__error_tooltip" v-show="errors.has('phone')">
        <p class="FormInput__tooltip_text typography__micro">
          {{ errors.first('phone') }}
        </p>
      </div>
    </label>
  </div>
  <label class="FormInput__root typography__textStyrene MainPageForm__email" v-else-if="form === 'main'">
    <input type="text" v-validate="'required|email'" :class="errors.has('email') ? 'invalid FormInput__notValid' : ''" name="email" placeholder=" " class="FormInput__input" v-model="email" @input="checkEmail" @change="checkEmail">
    <span class="FormInput__placeholder">{{ getTranslatedLabel('email', lang) }}</span>
    <p class="ErrorMessage__root FormInput__errorMessage" v-show="errors.has('email') && errors.firstByRule('email','required')">
      {{ errors.firstByRule('email','required') }}
    </p>
    <p class="email-suggestion" v-show="emailSuggestion">
      {{ getTranslatedLabel('email-suggestion', lang) }} <span @click="email = emailSuggestion; emailSuggestion = false;">{{ emailSuggestion }}</span>?
    </p>
    <button type="button" class="FormInput__error_button" v-show="errors.has('email')">
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" class="FormInput__error_icon">
        <circle cx="16" cy="16" r="16" fill="#D94236"></circle>
        <rect x="15" y="22" width="2" height="2" fill="white"></rect>
        <rect x="15" y="8" width="2" height="12" fill="white"></rect>
      </svg>
    </button>
    <div class="FormInput__tooltip FormInput__error_tooltip" v-show="errors.has('email')">
      <p class="FormInput__tooltip_text typography__micro">
        {{ errors.first('email') }}
      </p>
    </div>
  </label>
  <label class="FormInput__root typography__textStyrene SmallForm__email typography__textStyrene" v-else-if="form === 'small'">
    <input type="text" v-validate="'required|email'" :class="errors.has('email') ? 'invalid FormInput__notValid' : ''" name="email" placeholder=" " class="FormInput__input" v-model="email" @input="checkEmail" @change="checkEmail">
    <span class="FormInput__placeholder">{{ getTranslatedLabel('email', lang) }}</span>
    <p class="ErrorMessage__root FormInput__errorMessage" v-show="errors.has('email') && errors.firstByRule('email','required')">
      {{ errors.firstByRule('email','required') }}
    </p>
    <p class="email-suggestion" v-show="emailSuggestion">
      {{ getTranslatedLabel('email-suggestion', lang) }} <span @click="email = emailSuggestion; emailSuggestion = false;">{{ emailSuggestion }}</span>?
    </p>
    <button type="button" class="FormInput__error_button" v-show="errors.has('email')">
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" class="FormInput__error_icon">
        <circle cx="16" cy="16" r="16" fill="#D94236"></circle>
        <rect x="15" y="22" width="2" height="2" fill="white"></rect>
        <rect x="15" y="8" width="2" height="12" fill="white"></rect>
      </svg>
    </button>
    <div class="FormInput__tooltip FormInput__error_tooltip" v-show="errors.has('email')">
      <p class="FormInput__tooltip_text typography__micro">
        {{ errors.first('email') }}
      </p>
    </div>
  </label>
</template>

<script>
import { mapState } from 'vuex'
import emailMisspelled, { top100 } from 'email-misspelled'
import { getTranslatedLabel } from '../../helpers'

const emailChecker = emailMisspelled({ domains: top100 })

export default {
  data: () => ({
    emailSuggestion: false
  }),
  computed: {
    lastname: {
      get () {
        return this.$store.state.donation.lastname
      },
      set (value) {
        this.$store.commit('updateDonation', { prop: 'lastname', value })
      }
    },
    firstname: {
      get () {
        return this.$store.state.donation.firstname
      },
      set (value) {
        this.$store.commit('updateDonation', { prop: 'firstname', value })
      }
    },
    email: {
      get () {
        return this.$store.state.donation.email
      },
      set (value) {
        this.$store.commit('updateDonation', { prop: 'email', value })
      }
    },
    phone: {
      get () {
        return this.$store.state.donation.phone
      },
      set (value) {
        this.$store.commit('updateDonation', { prop: 'phone', value })
      }
    },
    ...mapState({
      form: state => state.formType,
      currencies: state => Object.keys(state.currencies),
      hiddenFields: state => state.hiddenFields,
      isMobile: state => state.donation.mobile,
      lang: state => state.lang
    })
  },
  methods: {
    getTranslatedLabel,
    isHidden: function (fieldName) {
      return this.hiddenFields.indexOf(fieldName) > -1
    },
    checkEmail (e) {
      const value = e.target.value
      const suggestions = emailChecker(value)
      if (suggestions.length > 0) {
        this.emailSuggestion = suggestions[0].corrected
      } else {
        this.emailSuggestion = false
      }
    }
  },
  inject: ['$validator'],
  mounted () {
    const validationMessages = {
      custom: {
        email: {
          required: getTranslatedLabel('email-required-error', this.lang),
          email: getTranslatedLabel('email-format-error', this.lang)
        },
        phone: {
          regex: getTranslatedLabel('phone-format-error', this.lang)
        },
        lastname: {
          required: getTranslatedLabel('lastname-required-error', this.lang)
        }
      }
    }
    this.$validator.localize('ru', validationMessages)
  }
}
</script>

<style scoped>
.email-suggestion {
  -webkit-transition: opacity .2s ease;
  transition: opacity .2s ease;
  position: absolute;
  font-weight: 400;
  font-size: 12.1px;
  line-height: 20px;
  margin: 6px 0;
  z-index: 999;
}
.email-suggestion span {
  color: #d94236;
  cursor: pointer;
}
.BigForm__form.one-currency .CustomForm__form_label {
  width: 100%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}
@media screen and (min-width: 1920px) {
  .BigForm__form.one-currency .BigForm__form_label {
    width: 780px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 780px;
    flex: 0 0 780px;
    max-width: 780px;
  }
}
@media screen and (max-width: 1919px) and (min-width: 1600px) {
  .BigForm__form.one-currency .BigForm__form_label {
    width: 640px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 640px;
    flex: 0 0 640px;
    max-width: 640px;
  }
}
@media screen and (max-width: 1599px) and (min-width: 1280px) {
  .BigForm__form.one-currency .BigForm__form_label {
    width: 496px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 496px;
    flex: 0 0 496px;
    max-width: 496px;
  }
}
</style>
